import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import List from '@mui/material/List';
import ListItem from '@components/ListItem';
import ListSkeleton from './ListSkeleton';

const ListComponent = (props) => {
  const {
    ActionsComponent,
    activeId,
    additionalProps,
    AvatarComponent,
    items = [],
    isCreateButton,
    idKey,
    loading,
    onClick,
    PlaceholderComponent,
    TextComponent,
  } = props;

  const listItemRefs = useRef({});

  /**
   *  COMPONENTS
   */

  const list =
    items.length > 0
      ? items.map((item) => {
          const itemId = idKey ? get(item, idKey) : item.id;

          return (
            <ListItem
              ActionsComponent={ActionsComponent}
              activeId={activeId}
              additionalProps={additionalProps}
              AvatarComponent={AvatarComponent}
              key={itemId}
              listItemRefs={listItemRefs}
              item={item}
              itemId={itemId}
              onClick={onClick}
              TextComponent={TextComponent}
            />
          );
        })
      : PlaceholderComponent;

  return (
    <List
      subheader={<li />}
      sx={{
        backgroundColor: 'background.paper',
        flex: 1,
        overflow: 'auto',
        padding: 0,
        position: 'relative',
        paddingBottom: isCreateButton ? 8 : 0,
      }}
    >
      {loading ? <ListSkeleton /> : list}
    </List>
  );
};

ListComponent.propTypes = {
  ActionsComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  additionalProps: PropTypes.object,
  AvatarComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  items: PropTypes.array,
  isCreateButton: PropTypes.bool,
  idKey: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  PlaceholderComponent: PropTypes.node,
  TextComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default ListComponent;
