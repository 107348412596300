import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { useRxDB } from 'rxdb-hooks';
import { useMutation, useQuery } from '@apollo/client';
import { omitDeep } from '@patomation/omit-deep';

import { FAB_SX_PROPS, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import { REMOTE_VERSION } from '@database/consts';
import getDocTemplate from '@database/getDocTemplate';
import getDefaultModifiedOn from '@database/getDefaultModifiedOn';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import useIsMountedRef from '@geomagic/nam-react-core/utils/useIsMountedRef';
import MutationStartAdHocAssignment from '@graphql/mutations/MutationStartAdHocAssignment';
import QueryAdHocAssignments from '@graphql/queries/QueryAdHocAssignments';

import getOrderOptions from './getOrderOptions';

const ASSIGNMENT_COLLECTION_KEY = 'assignments';

const StartAdHocAssignment = (props) => {
  const { handleClickAssignment, isAssignmentsLoading, isMobile, isOnline, mapProps, userId } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [planning, setPlanning] = useState();
  const [assignmentType, setAssignmentType] = useState();
  const database = useRxDB();

  const isMountedRef = useIsMountedRef();
  const isSMDevice = useMediaQuery((mediaQueryTheme) => mediaQueryTheme.breakpoints.down('sm'));

  /* QUERIES */

  const { data } = useQuery(QueryAdHocAssignments, {
    skip: !isOnline,
    fetchPolicy: 'network-only',
  });

  const { adHocAssignments = [] } = data || {};

  const orderOptions = useMemo(() => getOrderOptions(adHocAssignments), [adHocAssignments]);
  const planningOptions = order ? order.plannings : [];
  const assignmentTypeOptions = planning ? planning.assignmentTypes : [];

  /* MUTATIONS */

  const [startAdHocAssignment] = useMutation(MutationStartAdHocAssignment);

  /* EVENT HANDLER */

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleStartAssignment = () => {
    setLoading(true);
    startAdHocAssignment({
      variables: {
        assignmentTypeId: assignmentType.id,
        planningId: planning.id,
        srid: mapProps.srid,
      },
    })
      .then(async ({ data: assignmentData }) => {
        const newAssignment = omitDeep(assignmentData.startAdHocAssignment, '__typename');
        const doc = getDocTemplate({ entity: newAssignment, mapProps, userId });
        const modifiedOn = await getDefaultModifiedOn(newAssignment);
        const assignmentCollection = await database[ASSIGNMENT_COLLECTION_KEY];

        await assignmentCollection.insert({
          ...doc,
          modifiedOn,
          remoteVersion: REMOTE_VERSION,
        });

        handleCloseDialog();
        handleClickAssignment({ entity: newAssignment });
      })
      .finally(() => isMountedRef.current && setLoading(false));
  };

  const handleSelectOrder = (event, newOrder) => {
    setOrder(newOrder);
    setPlanning(newOrder.plannings[0]);
    setAssignmentType(newOrder.plannings[0].assignmentTypes[0]);
  };

  const handleSelectPlanning = (event, newPlanning) => {
    setPlanning(newPlanning);
    setAssignmentType(newPlanning.assignmentTypes[0]);
  };

  const handleSelectAssignmentType = (event, newAssignmentType) => {
    setAssignmentType(newAssignmentType);
  };

  /*  EFFECTS */

  useEffect(() => {
    if (orderOptions && orderOptions.length > 0) {
      setOrder(orderOptions[0]);
      setPlanning(orderOptions[0].plannings[0]);
      setAssignmentType(orderOptions[0].plannings[0].assignmentTypes[0]);
    }
  }, [orderOptions]);

  if (adHocAssignments.length === 0) return null;

  /* COMPONENTS*/

  const DialogContent = (
    <Box sx={{ padding: 2 }}>
      <SingleSelect
        getOptionLabel={(item) => item.name}
        getOptionSelected={(option, item) => option.id === item.id}
        label={i18n.t('label.order')}
        onChange={handleSelectOrder}
        options={orderOptions}
        value={order}
      />
      <SingleSelect
        getOptionLabel={(item) => item.name}
        getOptionSelected={(option, item) => option.id === item.id}
        label={i18n.t('label.planning')}
        onChange={handleSelectPlanning}
        options={planningOptions}
        value={planning}
      />
      <SingleSelect
        getOptionLabel={(item) => item.name}
        getOptionSelected={(option, item) => option.id === item.id}
        label={i18n.t('label.assignmentType')}
        onChange={handleSelectAssignmentType}
        options={assignmentTypeOptions}
        value={assignmentType}
      />
    </Box>
  );

  const ActionsComponent = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={handleCloseDialog}>
        {i18n.t('button.cancel')}
      </Trigger>
      <Trigger {...PRIMARY_TRIGGER_PROPS} onClick={handleStartAssignment} isLoading={isLoading}>
        {i18n.t('button.start')}
      </Trigger>
    </>
  );

  return (
    <>
      <Trigger
        color="primary"
        disabled={isAssignmentsLoading}
        icon={<AddIcon />}
        onClick={handleOpenDialog}
        size="medium"
        sx={FAB_SX_PROPS}
        variant="fab"
      >
        {!isSMDevice ? i18n.t('assignment.button.newAdHocAssignment') : ''}
      </Trigger>
      <StackedDialog
        actions={ActionsComponent}
        content={DialogContent}
        isFullscreen={isMobile}
        handleClose={handleCloseDialog}
        open={dialogOpen}
        title={i18n.t('assignment.dialog.newAdHocAssignment.title')}
      />
    </>
  );
};

StartAdHocAssignment.propTypes = {
  handleClickAssignment: PropTypes.func.isRequired,
  isAssignmentsLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

export default StartAdHocAssignment;
