import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import DispatchIcon from '@mui/icons-material/SpeakerNotes';
import { i18n } from '@geomagic/i18n';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import useAppBar from '@components/AppBar/useAppBar';
import GroupedList from '@components/GroupedList';
import List from '@components/List';
import { POSITION_SETTINGS_KEY } from '@components/Map';
import useFeatures from '@components/Map/utils/useFeatures';
import Placeholder from '@components/Placeholder';
import ActionsComponent from './DispatchListActions';
import AvatarComponent from './DispatchListAvatar';
import TextComponent from './DispatchListText';
import getDefaultFeatureStyle from './getFeatureStyle';
import getFeatures from './getFeatures';
import getGroups from './getGroups';

const DispatchList = (props) => {
  const {
    areMapActionsDisabled,
    className,
    client,
    dispatches,
    dispatchCreationConfig,
    entityClasses,
    isCreateButton,
    isDetail,
    isGrouped,
    isLoading,
    isMobile,
    isOnline,
    listPlaceholderComponent,
    mapProps,
    onClick,
    onCloseSwipeableArea,
    onRemoveDraft,
    user,
  } = props;

  const [positionSettings, setPositionSettings] = useStickySessionState(POSITION_SETTINGS_KEY);
  const { isTracking = false, moveLocation = false } = positionSettings || {};

  const { setTitle } = useAppBar();
  const [activeItemId, setActiveItemId] = useState();
  const [features, setFeatures] = useState([]);
  const theme = useTheme();

  const { mapRef, maxExtentZoomLevel, primaryColor, selectColor } = mapProps;

  /**
   *  EVENT HANDLER
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

  const handleSelectFeature = useCallback((event, feature) => {
    const entity = feature?.get('entity');
    setActiveItemId((prev) => (entity ? entity?.id : null));
  }, []);

  const handleShowOnMap = (mapFeatures, entityId) => {
    if (isTracking) {
      setPositionSettings((prevState) => ({ ...prevState, moveLocation: false }));
      // Timeout is needed to notice location state changes.
      setTimeout(() => {
        selectFeatures(mapFeatures);
      }, 50);
    } else {
      selectFeatures(mapFeatures);
    }
    setActiveItemId(entityId);
  };

  /**
   *  MAP
   */

  const { animateFeatures, selectFeatures } = useFeatures(
    { mapRef, features, maxExtentZoomLevel, selectColor, style: getFeatureStyle },
    { onSelect: handleSelectFeature }
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    !isDetail && setTitle(i18n.t('type.dispatches'));
  }, [isDetail, setTitle]);

  useEffect(() => {
    if (!isLoading && !isDetail) {
      const _features = getFeatures(dispatches, entityClasses);
      setFeatures(_features);
    }
  }, [dispatches, entityClasses, isDetail, isLoading]);

  useEffect(() => {
    if (!isDetail && !isTracking && !moveLocation) {
      animateFeatures();
    }
  }, [animateFeatures, features, isDetail, isTracking, moveLocation]);

  /**
   *  COMPONENTS
   */

  const getContentComponent = () => {
    let Component;

    if (dispatches.length < 1 && !isLoading) {
      Component = listPlaceholderComponent ? (
        listPlaceholderComponent
      ) : (
        <Placeholder
          icon={<DispatchIcon />}
          title={i18n.t('dispatch.placeholder.noDispatches.title')}
          subtitle={i18n.t('dispatch.placeholder.noDispatches.subtitle')}
        />
      );
    } else {
      const componentProps = {
        onClick,
        ActionsComponent,
        AvatarComponent,
        TextComponent,
      };

      const groups = isGrouped ? getGroups({ items: dispatches, componentProps }) : null;

      const additionalContextProps = {
        areMapActionsDisabled,
        client,
        dispatchCreationConfig,
        entityClasses,
        isDetail,
        isMobile,
        isOnline,
        mapProps,
        onCloseSwipeableArea,
        onRemoveDraft,
        onShowOnMap: handleShowOnMap,
        user,
      };

      Component = isGrouped ? (
        <GroupedList
          className={className}
          activeId={activeItemId}
          additionalProps={additionalContextProps}
          groups={groups}
          loading={isLoading}
          idKey="entity.id"
        />
      ) : (
        <List
          className={className}
          activeId={activeItemId}
          additionalProps={additionalContextProps}
          {...componentProps}
          isCreateButton={isCreateButton}
          items={dispatches}
          loading={isLoading}
          idKey="entity.id"
        />
      );
    }

    return Component;
  };

  return getContentComponent();
};

DispatchList.propTypes = {
  areMapActionsDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  dispatchCreationConfig: PropTypes.object,
  dispatches: PropTypes.array.isRequired,
  entityClasses: PropTypes.array.isRequired,
  isCreateButton: PropTypes.bool,
  isDetail: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  listPlaceholderComponent: PropTypes.node,
  mapProps: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onCloseSwipeableArea: PropTypes.func,
  onRemoveDraft: PropTypes.func,
  user: PropTypes.object,
};

export default DispatchList;
