import gql from 'graphql-tag';
import FragmentNamedEntityReference from '../fragments/FragmentNamedEntityReference';

const QueryAdHocAssignments = gql`
  ${FragmentNamedEntityReference}

  query QueryAdHocAssignments {
    adHocAssignments {
      assignmentType {
        ...NamedEntityReference
      }
      order {
        ...NamedEntityReference
      }
      planning {
        ...NamedEntityReference
      }
    }
  }
`;

export default QueryAdHocAssignments;
