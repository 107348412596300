export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const LOCALIZED_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ssTZD';
export const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY';
export const TIME_DISPLAY_FORMAT = 'HH:mm:ss';
export const DATETIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT} ${TIME_DISPLAY_FORMAT}`;
export const DEFAULT_IMAGE_TYPE = 'image/jpeg';
export const DEFAULT_STATE_KEY = 'GlobalState';
export const LOGIN_TOKEN_KEY = 'LoginToken';
export const LOGIN_WITH_CREDENTIALS_PATH = '/login';
export const LOGIN_WITH_TOKEN_PATH = '/ssoLogin';
export const MOBILE_TRIGGER_SIZE = 40;

export const DEFAULT_DOCUMENT_ACCEPT_FORMATS = [
  'image/*',
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
];

export const DEFAULT_LOCATION_ACCEPT_FORMATS = ['.gpx'];

export const DEFAULT_TEXT_FIELD_PROPS = {
  variant: 'outlined',
  margin: 'normal',
};

export const DEFAULT_TRIGGER_ICON_PROPS = {
  color: 'inherit',
};

export const DEFAULT_TRIGGER_PROPS = {
  color: 'primary',
  disableElevation: true,
  size: 'small',
  variant: 'text',
};

export const SECONDARY_TRIGGER_PROPS = {
  color: 'primary',
  variant: 'text',
  size: 'small',
};

export const PRIMARY_TRIGGER_PROPS = {
  color: 'primary',
  variant: 'contained',
  size: 'small',
  disableElevation: true,
};

export const DEFAULT_TRIGGER_PROPS_OUTLINED = {
  color: 'primary',
  variant: 'outlined',
  size: 'small',
};

export const CREATE_DISPATCHES_KEY = 'Dispatch.createDispatches';
export const STANDARD_ERROR_KEY = '_error_';

export const FORM_PATH = '/forms';
export const FORM_TYPENAME = 'Form';

export const OFFLINE_DOCUMENT_SYNC_KEY = 'OfflineDocuments.isSyncDocuments';

export const FAB_SX_PROPS = {
  bottom: 16,
  position: 'absolute',
  right: 16,
  zIndex: 3,
};
