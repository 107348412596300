import gql from 'graphql-tag';

const FragmentNamedEntityReference = gql`
  fragment NamedEntityReference on NamedEntityReference {
    id
    className
    name
  }
`;

export default FragmentNamedEntityReference;
