const getAssignmentTypes = (orderId, planningId, adHocAssignments) => {
  return adHocAssignments.reduce((prev, adHocAssignment) => {
    const currentAssignmentTypeId = adHocAssignment.assignmentType.id;
    const isOrder = adHocAssignment.order.id === orderId;
    const isPlanning = adHocAssignment.planning.id === planningId;
    const isNotAddedAssignmentType = !prev.some((assTc) => assTc.id === currentAssignmentTypeId);
    if (isOrder && isPlanning && isNotAddedAssignmentType) {
      prev.push(adHocAssignment.assignmentType);
    }
    return prev;
  }, []);
};

const getPlanningsForOrder = (orderId, adHocAssignments) => {
  return adHocAssignments.reduce((prev, adHocAssignment) => {
    const currentPlanningId = adHocAssignment.planning.id;
    const isOrder = adHocAssignment.order.id === orderId;
    const isNotAddedPlanning = !prev.some((pln) => pln.id === currentPlanningId);
    if (isOrder && isNotAddedPlanning) {
      const newPlanningOption = {
        ...adHocAssignment.planning,
        assignmentTypes: getAssignmentTypes(orderId, currentPlanningId, adHocAssignments),
      };
      prev.push(newPlanningOption);
    }
    return prev;
  }, []);
};

const getOrderOptions = (adHocAssignments) => {
  return adHocAssignments.reduce((prev, adHocAssignment) => {
    const currentOrderId = adHocAssignment.order.id;
    const isNotAddedOrder = !prev.some((ord) => ord.id === currentOrderId);
    if (isNotAddedOrder) {
      const newOrderOption = {
        ...adHocAssignment.order,
        plannings: getPlanningsForOrder(currentOrderId, adHocAssignments),
      };
      prev.push(newOrderOption);
    }
    return prev;
  }, []);
};

export default getOrderOptions;
