import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';

import PullToRefresh from '@components/PullToRefresh';
import { checkFormIsFinished } from '@components/FormComponent';
import QueryAssignment from '@graphql/queries/QueryAssignment';
import syncAssignment from '@synchronization/assignment/sync';
import transformAssignment from '@synchronization/assignment/transform';
import getUpdatedDoc from '@database/getUpdatedDoc';
import resetDoc from '@database/resetDoc';

import AssignmentDetail from './AssignmentDetail';
import AssignmentList from './AssignmentList';
import getDefaultFeatureStyle from './getFeatureStyle';
import Tasks from '../Tasks';
import CongratulationsProvider from '../Tasks/Congratulations/CongratulationsProvider';
import StartAdHocAssignment from '../StartAdHocAssignment/StartAdHocAssignment';

const Assignment = (props) => {
  const {
    assignments,
    basePath,
    client,
    database,
    entityClasses,
    getPreviousMap,
    history,
    isLoading,
    isMobile,
    isOnline,
    isTeamAssignment,
    LoadingComponent,
    mapProps,
    onClaimAssignment,
    onCloseSwipeableArea,
    onFetchData,
    stateRef,
    user,
    vectorTileServerUrl,
  } = props;

  const { primaryColor } = mapProps;

  const { id: paramsId } = useParams();

  const theme = useTheme();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [dialogTitle, setDialogTitle] = useState();

  const assignment = paramsId ? assignments.find((item) => item.entity.id.toString() === paramsId) : null;

  /**
   *  EVENT HANDLER
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

  const handleClickAssignment = ({ entity }) => {
    history.push(`${basePath}/${entity.id}`);
  };

  const handleCheckForOpenDrafts = async (doc) => {
    const { relevantDispatches } = doc.getPatchedEntity();

    if (relevantDispatches.length === 0) return true;

    const selector = {
      'draft.closed': false,
      'entity.id': { $in: relevantDispatches.map((dispatch) => dispatch.id) },
    };
    const collection = database.dispatches;
    const openDrafts = await collection.find({ selector: { ...selector, userId: user.id } }).exec();

    return openDrafts?.length === 0;
  };

  const handleSyncAssignment = async (doc) => {
    const syncProps = {
      client,
      database,
      doc,
      entityClasses,
      mapProps,
      refetchQuery: QueryAssignment,
      transform: transformAssignment,
    };
    await syncAssignment(syncProps);
  };

  const handleUpdateAssignment = async (doc) => {
    const entityReference = getReference(doc?.entity);
    const updatedEntity = await getUpdatedDoc(client, QueryAssignment, entityReference, mapProps);

    if (updatedEntity) {
      await resetDoc(doc, updatedEntity);
    }
  };

  const handleDeleteAssignment = async (doc) => {
    await doc.remove();
  };

  const handleOpenTasks = (data) => {
    const entity = data?.getPatchedEntity();
    const name = entity?.displayName;

    const { forms } = entity;

    const isFormFinished = checkFormIsFinished(forms);
    const isCloseable = isFormFinished && isOnline;

    setDialogProps({ data, isCloseable });
    setDialogOpen(true);
    setDialogTitle(i18n.t('dialog.tasks.title', { variables: { name } }));
  };

  const handleCloseDialog = () => setDialogOpen(false);

  /**
   *  COMPONENTS
   */

  const DialogContent = (
    <Tasks
      checkFailedMessage={i18n.t('assignment.notification.openDraft')}
      closeableErrorText={i18n.t('assignment.description.formNotFinished')}
      entityClasses={entityClasses}
      isMobile={isMobile}
      isOnline={isOnline}
      onCheck={handleCheckForOpenDrafts}
      onSyncEntity={handleSyncAssignment}
      onUpdateEntity={handleUpdateAssignment}
      user={user}
      {...dialogProps}
    />
  );

  return (
    <CongratulationsProvider>
      {assignment ? (
        <AssignmentDetail
          assignment={assignment}
          basePath={basePath}
          client={client}
          entityClasses={entityClasses}
          getFeatureStyle={getFeatureStyle}
          getPreviousMap={getPreviousMap}
          handleCheckForOpenDrafts={handleCheckForOpenDrafts}
          isMobile={isMobile}
          isOnline={isOnline}
          mapProps={mapProps}
          onDeleteAssignment={handleDeleteAssignment}
          onOpenTasks={handleOpenTasks}
          onSyncAssignment={handleSyncAssignment}
          stateRef={stateRef}
          user={user}
        />
      ) : (
        <>
          <PullToRefresh
            isPullable={!!(!isLoading && isOnline)}
            LoadingComponent={LoadingComponent}
            onRefresh={onFetchData}
          >
            <AssignmentList
              assignments={assignments}
              client={client}
              entityClasses={entityClasses}
              getFeatureStyle={getFeatureStyle}
              handleCheckForOpenDrafts={handleCheckForOpenDrafts}
              isLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              isTeamAssignment={isTeamAssignment}
              mapProps={mapProps}
              onClaimAssignment={onClaimAssignment}
              onClick={isTeamAssignment ? null : handleClickAssignment}
              onCloseSwipeableArea={onCloseSwipeableArea}
              onDeleteAssignment={handleDeleteAssignment}
              onOpenTasks={handleOpenTasks}
              onSyncAssignment={handleSyncAssignment}
              onUpdateAssignment={handleUpdateAssignment}
              user={user}
              vectorTileServerUrl={vectorTileServerUrl}
            />
          </PullToRefresh>
          {!isTeamAssignment && (
            <StartAdHocAssignment
              handleClickAssignment={handleClickAssignment}
              isAssignmentsLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              mapProps={mapProps}
              userId={user.id}
            />
          )}
        </>
      )}
      <StackedDialog
        content={DialogContent}
        isFullscreen={isMobile}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={dialogTitle}
      />
    </CongratulationsProvider>
  );
};

Assignment.propTypes = {
  assignments: PropTypes.array,
  basePath: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  database: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getPreviousMap: PropTypes.func,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  isTeamAssignment: PropTypes.bool.isRequired,
  LoadingComponent: PropTypes.func,
  mapProps: PropTypes.object.isRequired,
  onClaimAssignment: PropTypes.func,
  onCloseSwipeableArea: PropTypes.func.isRequired,
  onFetchData: PropTypes.func.isRequired,
  querySelectorProps: PropTypes.object,
  stateRef: PropTypes.object.isRequired,
  user: PropTypes.object,
  vectorTileServerUrl: PropTypes.string,
};

export default Assignment;
